import React from "react"

const PrivacyPolicy = () => {
  const BusinessDetails = {
    name: "IzabelleArt",
    email: "izabellewilding@gmail.com",
    dateUpdated: "06/05/2020",
  }

  return (
    <p>
      <h1> {BusinessDetails.name} PRIVACY POLICY </h1>
      <p>
        Thank oyu for visiting. {BusinessDetails.name} (the “Business”) is
        committed to protecting the privacy of our users. This Privacy Policy
        (“Privacy Policy”) is designed to help you understand what information
        we gather, how we use it, what we do to protect it, and to assist you in
        making informed decisions when using our Service. Unless otherwise
        indicated below, this Privacy Policy applies to any website that
        references this Privacy Policy. For purposes of this Agreement,
        “Service” refers to the Business’s service which can be accessed via our
        website, hosted by faso.com . The terms “we,” “us,” and “our” refer to
        the Business. “You” refers to you, as a user of Service.
      </p>{" "}
      <p>
        1. WHEN WE COLLECT INFORMATION AND CONSENT 1.1 By accessing our Service,
        you accept our Privacy Policy and Terms of Use, and you consent to our
        collection, storage, use and disclosure of your personal information as
        described in this Privacy Policy. In addition, by using our Service, or
        services across partnered and unaffiliated sites, you are accepting the
        policies and practices described in this Privacy Policy. Each time you
        visit our website, or use the Service, and any time you voluntarily
        provide us with information, you agree that you are consenting to our
        collection, use and disclosure of the information that you provide, and
        you are consenting to receive emails or otherwise be contacted, as
        described in this Privacy Policy. Whether or not you register or create
        any kind of account with us, this Privacy Policy applies to all users of
        the website and the Service.
      </p>
      <p>
        2. INFORMATION WE COLLECT 2.1 We may collect both “Non-Personal
        Information” and “Personal Information” about you. “Non-Personal
        Information” includes information that cannot be used to personally
        identify you, such as anonymous usage data, general demographic
        information we may collect, referring/exit pages and URLs, platform
        types, preferences you submit and preferences that are generated based
        on the data you submit and number of clicks. “Personal Information”
        includes information that can be used to personally identify you, such
        as your name, address and email address. 2.2 In addition, we may also
        track information provided to us by your browser when you view or use
        the Service, such as the website you came from (known as the “referring
        URL”), the type of browser you use, the device from which you connected
        to the Service, the time and date of access, and other information that
        does not personally identify you. We use this information for, among
        other things, the operation of the Service, to maintain the quality of
        the Service, to provide general statistics regarding use of the Service
        and for other business purposes. We track this information using
        cookies, or small text files which include an anonymous unique
        identifier. Cookies are sent to a user’s browser from our servers and
        are stored on the user’s computer hard drive. Sending a cookie to a
        user’s browser enables us to collect Non-Personal Information about that
        user and keep a record of the user’s preferences when utilizing our
        services, both on an individual and aggregate basis. The Business may
        use both persistent and session cookies; persistent cookies remain on
        your computer after you close your session and until you delete them,
        while session cookies expire when you close your browser. Persistent
        cookies can be removed by following your Internet browser help file
        directions. If you choose to disable cookies, some areas of the Service
        may not work properly.
      </p>
      <p>
        {" "}
        3. HOW WE USE AND SHARE INFORMATION 3.1 Personal Information - We do not
        sell, trade, rent or otherwise share your Personal Information with
        third parties without your consent, with the following exceptions: We
        may share your Personal Information with vendors and other third- party
        providers who are performing services for the Business. In general, the
        vendors and third-party providers used by us will only collect, use and
        disclose your information to the extent necessary to allow them to
        perform the services they provide for the Business. For example, when
        you provide us with personal information to complete a transaction,
        verify your credit card, place an order, arrange for a delivery, or
        return a purchase, you consent to our collecting and using such personal
        information for that specific purpose, including by transmitting such
        information to our vendors (and their service providers) performing
        these services for the Business. In plain english: If you purchase a
        piece of art, Paypal will receive personal information and our shipper
        will receive personal information about you as those are necessary to
        complete the transaction. 3.2 Certain third-party service providers,
        such as payment processors, have their own privacy policies in respect
        of the information that we are required to provide to them in order to
        use their services. For these third-party service providers, we
        recommend that you read their privacy policies so that you can
        understand the manner in which your Personal Information will be handled
        by such providers. 3.3 In addition, we may disclose your Personal
        Information if required to do so by law or if you violate our Terms of
        Use. 3.4 Non-personal information - In general, we use Non-Personal
        Information to help us improve the Service and customize the user
        experience. We also aggregate Non-Personal Information in order to track
        trends and analyze use patterns of the Service. This Privacy Policy does
        not limit in any way our use or disclosure of Non-Personal Information
        and we reserve the right to use and disclose such Non-Personal
        Information to our partners, advertisers and other third parties at our
        sole discretion.{" "}
      </p>
      4. HOW WE PROTECT INFORMATION 4.1 We implement reasonable precautions and
      follow industry best practices in order to protect your Personal
      Information and ensure that such Personal Information is not accessed,
      disclosed, altered or destroyed. However, these measures do not guarantee
      that your information will not be accessed, disclosed, altered or
      destroyed by breach of such precautions. By using our Service, you
      acknowledge that you understand and agree to assume these risks. 5. YOUR
      RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION 5.1 You have the
      right, at any time, to prevent us from contacting you for marketing
      purposes. When we send a promotional communication you via FASO, you can
      opt out of further promotional communications by following the unsubscribe
      instructions provided in each promotional e-mail. Please note that even
      after you unsubscribe from any promotional communications, we may continue
      to send you administrative emails including, for example, invoice
      confirmation of an artwork purchase or other communication necessary for a
      transaction. 6. FASO 6.1 Our Service is hosted by BoldBrush, Inc - dba
      FASO Business Websites (FASO). FASO provides us with the online e-commerce
      platform that allows us to provide the Service to you. Your information,
      including Personal Information, may be stored through FASO's servers. By
      using the Service, you consent to FASO's collection, disclosure, storage,
      and use of your Personal Information in accordance with FASO's privacy
      policy available at https://faso.com/privacy. 7. LINKS TO OTHER WEBSITES
      7.1 As part of the Service, we may provide links to or compatibility with
      other websites or applications. However, we are not responsible for the
      privacy practices employed by those websites or the information or content
      they contain. This Privacy Policy applies solely to information collected
      by us through the Service. Therefore, this Privacy Policy does not apply
      to your use of a third-party website accessed by selecting a link via our
      Service. To the extent that you access or use the Service through or on
      another website or application, then the privacy policy of that other
      website or application will apply to your access or use of that site or
      application. We encourage our users to read the privacy statements of
      other websites before proceeding to use them. 8. AGE OF CONSENT 8.1 By
      using the Service, you represent that you are at least 18 years of age. 9.
      CHANGES TO OUR PRIVACY POLICY 9.1 We reserve the right to change this
      Privacy Policy and our Terms of Use at any time. If we decide to change
      this Privacy Policy, we will post these changes on this page so that you
      are always aware of what information we collect, how we use it, and under
      what circumstances we disclose it. Any such modifications become effective
      upon your continued access to and/or use of the Service five (5) days
      after we first post the changes on the website or otherwise provide you
      with notice of such modifications. It is your sole responsibility to check
      this website from time to time to view any such changes to the terms of
      this Privacy Policy. If you do not agree to any changes, if and when such
      changes may be made to this Privacy Policy, you must cease access to this
      website. If you have provided your email address to us, you give us
      permission to email you for the purpose of notification as described in
      this Privacy Policy. 10. MERGER OR ACQUISITION 10.1 In the event we (or
      FASO) undergo a business transaction such as a merger, acquisition by
      another Business, or sale of all or a portion of our assets, your Personal
      Information may be among the assets transferred. You acknowledge and
      consent that such transfers may occur and are permitted by this Privacy
      Policy, and that any acquirer of our (or FASO's) assets may continue to
      process your Personal Information as set forth in this Privacy Policy. If
      our information practices change at any time in the future, we will post
      the policy changes here so that you may opt out of the new information
      practices. 11. CONTACT US & WITHDRAWING CONSENT 11.1 If you have any
      questions regarding this Privacy Policy or the practices of this Site, or
      wish to withdraw your consent for the continued collection, use or
      disclosure of your Personal Information, please contact us by sending an
      email to {BusinessDetails.email} . Last Updated: This Privacy Policy was
      last updated on {BusinessDetails.dateUpdated} .
    </p>
  )
}

export default PrivacyPolicy
