import React from "react"
import Layout from "../components/layout.js"
import PrivacyPolicy from "../components/privacy-policy.js"

const Privacy = () => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  )
}

export default Privacy
